<template>
  <Modal
    :trigger="MODAL_CONFIG.trigger"
    :content-classes="MODAL_CONFIG.contentClasses"
  >
    <template #content>
      <h3 class="mb-8">Privacy Policy</h3>
      <h5 class="text-lg mb-6">1. Introduction</h5>

      <!-- <h5 class="text-lg mb-6">12. Miscellaneous</h5> -->
      <div class="mb-6 pb-6 border-b border-truegray-500">
        <p class="text-truegray-500">
          Schooled Limited (<a
            href="https://www.schooled.nz/"
            rel="noopener noreferrer"
            target="_blank"
            >https://www.schooled.nz/</a
          >) and Hail.IM Limited (<a
            href="https://get.hail.to/"
            rel="noopener noreferrer"
            target="_blank"
            >https://get.hail.to/</a
          >
          ) referred to as "we," "us," or "our" is committed to protecting your
          privacy and safeguarding any personal information you provide when
          using our Board Portal service. This Privacy Policy outlines how we
          collect, use, disclose, store, and protect your personal information
          in accordance with the Privacy Act 2020 (New Zealand).
        </p>

        <p class="text-truegray-500">
          By using our services, you consent to the practices described in this
          Privacy Policy.
        </p>
      </div>

      <h5 class="text-lg mb-6">2. Scope of this Privacy Policy</h5>
      <div class="mb-6 pb-6 border-b border-truegray-500">
        <p class="text-truegray-500">
          This policy applies whenever we collect personal information from you,
          whether through our Board Portal application, website, or related
          services. It covers how we handle information from registration,
          storing information and account management.
        </p>
      </div>

      <h5 class="text-lg mb-6">3. Information We Collect</h5>
      <div class="mb-6 pb-6 border-b border-truegray-500">
        <p class="text-truegray-500">
          We may collect the following types of personal information:
        </p>
        <ul class="text-truegray-500">
          <li>
            <span class="font-bold">Account information:</span> Email address,
            username, and password.
          </li>

          <li>
            <span class="font-bold">Usage Data:</span> Metadata about your
            interactions with the application, including IP address, device
            type, operating system, browser type, and activity logs (e.g., pages
            viewed, buttons clicked).
          </li>

          <li>
            <span class="font-bold">Communication Data:</span> Records of
            changes within the application, activity and system logs.
          </li>

          <li>
            <span class="font-bold">Board Data:</span> Information uploaded by
            school board administrators, such as reports, minutes and board
            member details.
          </li>
        </ul>
      </div>

      <h5 class="text-lg mb-6">4. How We Use Your Information</h5>
      <div class="mb-6 pb-6 border-b border-truegray-500">
        <p class="text-truegray-500">
          We use your personal information for the following purposes:
        </p>
        <ul class="text-truegray-500">
          <li>
            <span class="font-bold">Service Provision:</span> To enable the
            operation of the board portal, processes and provide technical
            support.
          </li>

          <li>
            <span class="font-bold">Identity Verification:</span> To confirm
            your identity and ensure authorised access.
          </li>

          <li>
            <span class="font-bold">Communication:</span> To send updates, and
            notifications to users and you.
          </li>

          <li>
            <span class="font-bold">Improvement and Development:</span> To
            analyse user interactions and improve the performance and features
            of our application.
          </li>

          <li>
            <span class="font-bold">Compliance:</span> To meet legal,
            regulatory, and contractual obligations.
          </li>
        </ul>

        <p class="text-truegray-500">
          We reserve our right to use data (on an anonymous basis) in relation
          to your use of the Services for marketing and accounting purposes.
        </p>
        <p class="text-truegray-500">
          You may request that we stop sending marketing or promotional messages
          at any time, by contacting us at support@hail.to.
        </p>
      </div>

      <h5 class="text-lg mb-6">5. Sharing of Information</h5>
      <div class="mb-6 pb-6 border-b border-truegray-500">
        <p class="text-truegray-500">
          We do not sell or share your personal information to third parties.
          However, our technology provider (Hail.IM) does share information in
          the following circumstances:
        </p>
        <ul class="text-truegray-500">
          <li>
            <span class="font-bold">With Service Providers:</span> For hosting,
            data storage, and application development, we store information and
            process emails on Amazon Web Services in a US data repository.
          </li>

          <li>
            <span class="font-bold">Legal Compliance:</span> To comply with
            legal obligations or respond to lawful requests from legitimate
            public authorities.
          </li>

          <li>
            <span class="font-bold">With Your Consent:</span> When you
            explicitly authorise us to share specific information.
          </li>
        </ul>
      </div>

      <h5 class="text-lg mb-6">6. Data Storage and Security</h5>
      <div class="mb-6 pb-6 border-b border-truegray-500">
        <p class="text-truegray-500">
          We take reasonable steps to ensure your personal information is stored
          securely and protected against unauthorised access, alteration, or
          disclosure. Measures include:
        </p>
        <ul class="text-truegray-500">
          <li>Encryption of sensitive data during transmission.</li>

          <li>
            Regular security assessments and updates to our technical software
            to maintain system security and operational performance.
          </li>

          <li>
            Restricting access to authorised personnel only via a secure
            administrative portal.
          </li>

          <li>
            We also engage service providers outside of New Zealand to host and
            maintain the underlying IT system that we use to provide the
            application. We take all reasonable steps to ensure that your
            personal information held outside New Zealand is secure and held in
            compliance with this privacy policy.
          </li>
        </ul>
      </div>

      <h5 class="text-lg mb-6">7. Cookies and Tracking Technologies</h5>
      <div class="mb-6 pb-6 border-b border-truegray-500">
        <p class="text-truegray-500">
          We use cookies and similar technologies to enhance your user
          experience. Cookies help us understand how you interact with our
          services and allow us to:
        </p>
        <ul class="text-truegray-500">
          <li>Maintain your session and preferences.</li>

          <li>Monitor site performance and usage trends.</li>
        </ul>
        <p class="text-truegray-500">
          Cookies are stored for varying durations depending on their
          purpose—session cookies are temporary and expire when you close your
          browser, while persistent cookies remain on your device until they
          expire or are deleted.
        </p>

        <p class="text-truegray-500">
          You can manage or disable cookies through your browser settings or
          directly within the application, where available, to tailor your
          cookie preferences. Note that disabling cookies may affect certain
          functionalities of the application.
        </p>

        <p class="text-truegray-500">
          You can manage or disable cookies through your browser settings,
          though this may affect certain functionalities of the application.
        </p>
      </div>

      <h5 class="text-lg mb-6">8. Access and Control of Your Information</h5>
      <div class="mb-6 pb-6 border-b border-truegray-500">
        <p class="text-truegray-500">
          You have the following rights concerning your personal information:
        </p>
        <ul class="text-truegray-500">
          <li>
            <span class="font-bold">Access:</span> Request a copy of your
            personal data held in the portal.
          </li>
          <li>
            <span class="font-bold">Correction:</span> Request correction of
            inaccurate or incomplete information.
          </li>
          <li>
            <span class="font-bold">Deletion:</span> Request deletion of your
            personal information, subject to legal obligations.
          </li>
          <li>
            <span class="font-bold">Objection:</span> Object to processing for
            specific purposes, such as marketing.
          </li>
        </ul>
        <p class="text-truegray-500">
          To exercise these rights, contact us at support@hail.to.
        </p>
      </div>

      <h5 class="text-lg mb-6">9. Data Retention</h5>
      <div class="mb-6 pb-6 border-b border-truegray-500">
        <p class="text-truegray-500">
          We retain personal information only as long as necessary for:
        </p>
        <ul class="text-truegray-500">
          <li>Providing the board portal service.</li>
          <li>Meeting legal, regulatory, or contractual obligations</li>

          <li>Resolving disputes and enforcing agreements.</li>
        </ul>
        <p class="text-truegray-500">
          Personal information related to portal will be retained for up to a
          period of 90 days following the closure of an account. After this
          period, the data will be securely deleted unless otherwise required by
          law.
        </p>

        <p class="text-truegray-500">
          The retention period depends on the nature and sensitivity of the
          data, as well as applicable laws and policies.
        </p>
      </div>

      <h5 class="text-lg mb-6">10. Incident Management</h5>
      <div class="mb-6 pb-6 border-b border-truegray-500">
        <p class="text-truegray-500">
          In the event of a major business incident, we are committed to
          promptly and effectively responding to mitigate the impact on
          information systems, data, and overall operations. A major business is
          defined as any unauthorised access, disclosure, disruption,
          modification, or destruction of information systems or data. Customers
          are to raise any incidents via support@hail.to.
        </p>

        <p class="font-bold text-sm">Notification and Communication.</p>
        <p class="text-truegray-500">
          Upon detection of a cyber incident, the designated Incident Response
          Team (IRT) at Hail will be activated immediately to assess and respond
          to the threat. The IRT will be led by the General Manager of Schooled
          and Hail or a nominated representative. Users will be informed of any
          incidents affecting their data within 48 hours of detection through
          email notifications and updates on our website.
        </p>

        <p class="font-bold text-sm">
          Containment, Eradication and Communications.
        </p>
        <p class="text-truegray-500">
          The IRT will take immediate steps to contain and mitigate the impact
          of the incident. A communication strategy will be implemented to
          manage both internal and external messaging during and after the
          incident.
        </p>

        <p class="font-bold text-sm">Legal and Regulatory Compliance.</p>
        <p class="text-truegray-500">We will comply with all.</p>
      </div>

      <h5 class="text-lg mb-6">11. Changes to This Privacy Policy</h5>
      <div class="mb-6 pb-6 border-b border-truegray-500">
        <p class="text-truegray-500">
          We reserve the right to modify this Privacy Policy at any time.
          Changes will be posted on our application, and significant updates may
          be communicated directly to users through email notifications or
          in-app alerts.
        </p>

        <p class="text-truegray-500">
          Continued use of our services indicates acceptance of the revised
          policy. Continued use of our services indicates acceptance of the
          revised policy.
        </p>
      </div>

      <h5 class="text-lg mb-6">12. Contact Us</h5>
      <div class="mb-6 pb-6 border-b border-truegray-500">
        <p class="text-truegray-500">
          If you have questions or concerns about this Privacy Policy or our
          data practices, please contact us:
        </p>

        <p class="text-truegray-500">
          <span class="font-bold">Email: </span>
          <a
            href="mailto:support@hail.to"
            rel="noopener noreferrer"
            target="_blank"
            >support@hail.to</a
          >
        </p>
      </div>

      <p class="text-truegray-500">
        This Privacy Policy was last updated on 16 January 2025.
      </p>
    </template>
  </Modal>
</template>

<script>
import { defineComponent } from 'vue'
import Modal from './Modal.vue'
import { LINKS } from '../../constants.js'

export default defineComponent({
  name: 'ModalTerms',

  components: {
    Modal,
  },

  setup() {
    const MODAL_CONFIG = {
      trigger: {
        text: 'Privacy Policy',
        as: 'a',
        classes: 'brand__link--white-secondary',
      },

      contentClasses: 'max-h-[85vh] overflow-y-auto p-10',
    }

    return {
      LINKS,
      MODAL_CONFIG,
    }
  },
})
</script>

<style scoped>
li {
  list-style: disc;
  margin-left: 1rem;
}
</style>
