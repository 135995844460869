<template>
  <div
    class="w-full flex justify-between items-center lg:h-[5vh] max-w-7xl py-4 px-6 mx-auto"
  >
    <span class="text-white text-xs">
      © {{ new Date().getFullYear() }} Schooled App. Designed in partnership
      with
      <a
        :href="LINKS.HAIL"
        target="_blank"
        rel="noopener noreferrer"
        class="brand__link--white-secondary underline text-xs"
      >
        Hail
      </a>
    </span>
    <div class="flex space-x-4">
      <ModalPrivacy />
      <ModalTerms />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import ModalTerms from '../components/modal/ModalTerms.vue'
import ModalPrivacy from '../components/modal/ModalPrivacy.vue'
import { LINKS } from '../constants.js'

export default defineComponent({
  name: 'PageFooter',

  components: {
    ModalTerms,
    ModalPrivacy,
  },

  setup() {
    return {
      LINKS,
    }
  },
})
</script>
